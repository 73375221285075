import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['form']

  connect() { }

  submitForm(e) {
    event.preventDefault(); // Optional: Prevent default if necessary

    if (this.hasFormTarget) {
      this.formTarget.submit(); // Programmatically submit the form
    } else {
      console.error("No form target found!");
    }
  }

}
