import { Controller } from 'stimulus'; 

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export default class extends Controller {
  static targets = ['indicator']

  connect() { 
    this.indicatorTarget.classList.add("fa", "fa-spinner", "fa-spin");
    sleep(200).then(() => { 
      this.indicatorTarget.classList.remove("fa-spinner", "fa-spin");
      this.indicatorTarget.classList.add("fa-check", "success");
    });
    sleep(3000).then(() => { 
      this.indicatorTarget.classList.remove("fa-check", "success");
    });
  }

}

// https://codepen.io/nourabusoud/pen/QaXLjJ
